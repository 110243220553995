import React from "react";
import "../App.css";
import lcot from "../_img/logos/CoT_logo_RGB.svg";
import limec from "../_img/logos/imec_logo_rgb.svg";

const CFFooterPanel: React.FunctionComponent = () => {
  return (
    <footer className="footerstyle">
      <img src={lcot} alt=""></img>
      <img src={limec} alt=""></img>

      <p>This application does not collect any personal data!</p>
    </footer>
  );
};

export default CFFooterPanel;
