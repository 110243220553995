import * as React from "react";
import { GeoJsonLayer } from "@deck.gl/layers";

import * as colors from "./colors";
import geoJson from "../../data/antwerp/wijken_omgevingsinformatie.json";

export const useNeighborHoodLayer = (isVisible: boolean) => {
  const [hoverInfo, setHoverInfo] = React.useState<any>();

  // Hide tooltip when layer is not visible
  React.useEffect(() => {
    if (!isVisible) {
      setHoverInfo(null);
    }
  }, [isVisible]);

  return React.useMemo(
    () => ({
      layer: new GeoJsonLayer({
        visible: isVisible,
        id: "geojson-neighborhoods-layer",
        data: geoJson,
        pickable: true,
        stroked: true,
        filled: true,
        extruded: false,
        lineWidthScale: 10,
        lineWidthMinPixels: 2,
        getLineWidth: 5,
        getPointRadius: 4,
        getTextSize: 40,
        getFillColor: (info: any) => {
          const neighborhoodIsInHoverState =
            info.properties.OBJECTID ===
            hoverInfo?.object?.properties?.OBJECTID;
          if (neighborhoodIsInHoverState) {
            return colors.ACCENT_COLOR;
          }

          return colors.ACCENT_COLOR_100;
        },
        getLineColor: colors.ACCENT_COLOR,
        updateTriggers: {
          getFillColor: hoverInfo?.object?.properties?.OBJECTID,
        },
        onDrag: () => {
          // Tooltip is locked relative to the screen and not to the
          // current viewstate of the map), so reset the tooltip
          setHoverInfo(null);
        },
        onHover: (info: any) => {
          setHoverInfo(info);
        },
      }),
      hoverInfo,
    }),
    [hoverInfo, isVisible]
  );
};
