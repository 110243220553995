import "./timer.css";

import * as React from "react";

import { useStore } from "../../StoreContext";

const Bar: React.FC = () => {
  const store = useStore();

  const percentageLeft =
    ((store.currentBucket.duration - store.currentBucket.elapsed) /
      store.currentBucket.duration) *
    100;

  return (
    <div className="timer-bar-wrapper">
      <div
        className="timer-bar-fill"
        style={{
          width: `${(100 - percentageLeft) % 100}%`,
        }}
      ></div>
    </div>
  );
};

export default Bar;
