import "mapbox-gl/src/css/mapbox-gl.css";

import * as React from "react";
import DeckGL from "@deck.gl/react";
import { observer } from "mobx-react";
import { StaticMap } from "react-map-gl";

import { useStore } from "../../StoreContext";
import { useStreetLayer } from "./streetLayer";
import { useIconLayer } from "./iconLayer";
import { useNeighborHoodLayer } from "./neighborHoodLayer";
import SelectedSegmentHud from "./SelectedSegmentHud";
import { City } from "../../types";
import Tooltip from "../../components/Tooltip";

const Map: React.FC = observer(() => {
  const store = useStore();
  const setViewport = (e: any) => (store.viewport = e.viewState);
  const isNeighborHoodLayerVisible =
    store.viewport.zoom <= 13 && store.city === City.antwerp;
  const streetLayer = useStreetLayer(!isNeighborHoodLayerVisible);
  const iconLayer = useIconLayer();
  const { layer: neighborHoodLayer, hoverInfo } = useNeighborHoodLayer(
    isNeighborHoodLayerVisible
  );

  const isTooltipVisible = Boolean(hoverInfo?.object?.properties);

  const layers = [streetLayer, iconLayer, neighborHoodLayer];

  return (
    <div>
      <DeckGL
        viewState={store.viewport}
        onViewStateChange={setViewport}
        layers={layers}
        controller
      >
        <StaticMap
          reuseMaps
          mapStyle="mapbox://styles/mapbox/light-v9"
          preventStyleDiffing
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
          width="100vw"
          height="100vh"
        />
        {isTooltipVisible && (
          <Tooltip
            label={hoverInfo.object.properties.wijknaam}
            position={hoverInfo}
          />
        )}
      </DeckGL>
      <SelectedSegmentHud />
    </div>
  );
});

export default Map;
