import carLogo from "../_img/modalities/car.svg";
import bikeLogo from "../_img/modalities/bike.svg";
import veloLogo from "../_img/modalities/velo.svg";
import pedestrianLogo from "../_img/modalities/pedestrian.svg";
import truckLogo from "../_img/modalities/truck.svg";
import publicTransportLogo from "../_img/modalities/publicTransport.svg";
import { Modality } from "../types";

export const modalityMetaDataMap = {
  [Modality.car]: {
    logo: carLogo,
    caption: "Cars",
    info: "Cars, mini-busses motorcycles and mopeds",
  },
  [Modality.pedestrian]: {
    logo: pedestrianLogo,
    caption: "Pedestrians",
    info: "Pedestrians, wheelchairs and people pushing strollers",
  },
  [Modality.bike]: {
    logo: bikeLogo,
    caption: "Bikes",
    info: "Bicylces, speed-pedelecs, tandems, cargo-bikes, skateboards & hooverboards or people on a horse",
  },
  [Modality.velo]: {
    logo: veloLogo,
    caption: "Velo",
    info: "Velo Bike",
  },
  [Modality.truck]: {
    logo: truckLogo,
    caption: "Trucks",
    info: "All types of trucks, tractors, heavy machinery, small vans, lorries ",
  },
  [Modality.publicTransport]: {
    logo: publicTransportLogo,
    caption: "Public transport",
    info: "Bus & tram (De Lijn)",
  },
};
