import "./timer.css";

import * as React from "react";
import { observer } from "mobx-react";
import * as workerTimers from 'worker-timers';

import { useStore } from "../../StoreContext";
import Badges from "./Badges";
import Bar from "./Bar";
import locIcon from "../../_img/logos/location_w.svg";

const Timer: React.FunctionComponent = observer(() => {
  const store = useStore();

  React.useEffect(() => {
    const timeout = workerTimers.setInterval(() => {
      store.timer += 1;
    }, 1000);

    return () => {
      workerTimers.clearInterval(timeout);
    };
  }, [store]);

  return (
    <div className="timer-wrapper">
      <div className="countContainertitl">
        <div className="counting-wrapper">
          <p className="countContainerCount">Counting ...</p>
          <div className="count-time">{store.remainingTimeString}</div>
        </div>
        <div>
          <p className="countContainerLoc">
            <img src={locIcon} alt="locIcon" />
            {store.location?.streetName}
          </p>
          <Badges />
        </div>
      </div>
      <Bar />
    </div>
  );
});

export default Timer;
