import * as React from "react";
import { Redirect } from "react-router-dom";

import type { Store } from "./store";
import { City } from "./types";

const StoreContext = React.createContext<Store | null>(null);

export const useStore = (): Store => {
  const context = React.useContext(StoreContext);

  if (!context) {
    throw new Error("useStore must be used within a `StoreProvider`");
  }

  return context;
};

type Props = {
  store: Store;
};

export const StoreProvider: React.FC<Props> = ({ store, children }) => {
  // Route guard
  if (!Object.values(City).includes(store.city)) {
    return <Redirect to="/" />;
  }

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
