import * as React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { useUpdateCounterCallback } from "../../../hooks/counter";
import { useGeoLocation } from "../../../GeoLocationProvider";
import { useStore } from "../../../StoreContext";
import { Modality } from "../../../types";

import styles from "./index.module.css";
import locIcon from "../../../_img/logos/location_w.svg";
import crosshair from "../../../_img/logos/crosshair-fill.svg";

const SelectSegmentHudHeader: React.FC = observer(() => {
  const store = useStore();

  const handleStreetNameChange = React.useCallback(() => {
    const streetName = window.prompt(
      "Fill in street name and street number (if available) or a description of where you are counting",
      store.manualStreetName
    );

    store.manualStreetName = streetName ?? store.manualStreetName ?? "";
  }, [store]);

  const handleStreetNameClick = React.useCallback(() => {
    // Make edit only available when we enter a manual street name
    if (store.manualStreetName) {
      handleStreetNameChange();
    }
  }, [store.manualStreetName, handleStreetNameChange]);

  return store.location?.streetName ? (
    <div className="selectedSegmentlocIconWrapper">
      <img src={locIcon} alt="location icon"></img>
      <h2 onClick={handleStreetNameClick}>{store.location?.streetName}</h2>
    </div>
  ) : (
    <>
      <h2>Select your street to start</h2>
      <p>
        Or click{' '}
        <strong className={styles.link} onClick={handleStreetNameChange}>
          here
        </strong>{' '}
        to fill in manually
      </p>
    </>
  );
});

const SelectedSegmentHud: React.FC = observer(() => {
  const store = useStore();
  const { location } = store;
  const history = useHistory();
  const updateCounter = useUpdateCounterCallback();
  const [getGeoLocation, { position, error, isSupported }] = useGeoLocation();

  const updateStoreViewport = React.useCallback(() => {
    if (position) {
      store.viewport = {
        ...store.viewport,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
    }
  }, [position, store]);

  React.useEffect(() => {
    updateStoreViewport();
  }, [updateStoreViewport]);

  const getGeoLocationAndCenterMap = React.useCallback(() => {
    getGeoLocation();
    updateStoreViewport();
  }, [updateStoreViewport, getGeoLocation]);

  const handlePropagnation = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
    },
    []
  );

  const handleConfirm = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      updateCounter(Modality.car, 0);
      store.observationStart = new Date();
      history.push(`/${store.city}/counter`);
    },
    [history, store, updateCounter]
  );

  return (
    <div className="selectedSegmentStyle" onClick={handlePropagnation}>
      <SelectSegmentHudHeader />
      <div className="selectedSegmentTitle">
        {location?.id && <div>Segment ID: {location.id}</div>}
      </div>
      <button disabled={!location} onClick={handleConfirm}>
        CONFIRM
      </button>
      <button
        className={styles.fab}
        type="button"
        onClick={getGeoLocationAndCenterMap}
        disabled={!isSupported || Boolean(error)}
      >
        <img className={styles.icon} src={crosshair} alt="Focus my location" />
      </button>
    </div>
  );
});

export default SelectedSegmentHud;
