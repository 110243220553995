import * as React from "react";

import styles from "./index.module.css";

type Position = { x: number; y: number };

type Props = {
  label: string;
  position: Position;
};

const Tooltip: React.FC<Props> = ({ label, position }) => {
  const ref = React.useRef<HTMLSpanElement>(null);
  const [clientWidth, setClientWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    setClientWidth(ref.current?.clientWidth ?? 0);
  }, []);

  const dynamicStyles: React.CSSProperties = {
    left: position.x,
    top: position.y,
    // Offset
    transform: `translateX(-${clientWidth / 2}px)`,
  };

  return (
    <span ref={ref} className={styles.tooltip} style={dynamicStyles}>
      {label}
    </span>
  );
};

export default Tooltip;
