export enum City {
  antwerp = "antwerp",
  mechelen = "mechelen",
  ghent = "ghent",
}

export enum Modality {
  car = "car",
  pedestrian = "pedestrian",
  bike = "bike",
  velo = "velo",
  truck = "truck",
  publicTransport = "public transport",
}

export interface Observation {
  sessionId: string;
  timestamp: number;
  modality: Modality;
  count: number;
  segmentId: number | string;
  manualStreetName: string;
}

export interface TimeSlot {
  start: number;
  end: number;
}

export type Session = { [key: string]: Observation };
export interface TimeSlotAggregate {
  car: number;
  pedestrian: number;
  bike: number;
  start: number;
  end: number;
  sessionId: string;
  objectId: string;
}

export interface Metadata {
  sessionId: string;
  feedback?: string;
  trafficPressure?: string;
}

export interface SegmentProperties {
  OBJECTID: number;
  WEGNUMMER?: number;
  CAT_RSPA?: any;
  CAT_MOBILITEITSPLAN?: any;
  WEGCAT?: string;
  WS_OIDN: number;
  WS_UIDN?: string;
  WS_GIDN?: string;
  B_WK_OIDN?: number;
  E_WK_OIDN?: number;
  MORF?: string;
  LSTRNMID?: number;
  LSTRNM?: string;
  RSTRNMID?: number;
  RSTRNM?: string;
  BEHEER?: string;
  METHODE?: string;
  OPNDATUM?: number;
  BEGINTIJD?: number;
  BEGINORG?: string;
  TGBEP?: string;
  GBKA_ID?: null;
  SHAPE_Length?: number;
  modal_split_car?: number;
  modal_split_bike?: number;
  modal_split_pedestrian?: number;
  modal_split_public_transport?: number;
  modal_split_truck?: number;
  modal_split_motorized?: number;
  modal_split_non_motorized?: number;

  GISID?: string;
  NAAM?: string;
  POSTCODE?: string;
  DISTRICT?: string;
  GEMEENTE?: string;
  SHAPE_Area?: number;
}
