import { groupBy, flatten } from "lodash-es";
import {
  Modality,
  Observation,
  Session,
  TimeSlot,
  TimeSlotAggregate,
  Metadata,
} from "./types";

export const getTotal = (array: Observation[]) => {
  return array.reduce((acc: number, item: Observation) => {
    return acc + item.count;
  }, 0);
};

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

const calculateTimeSteps = (
  start: number,
  end: number,
  stepSize: number
): TimeSlot[] => {
  const result = [];
  // calculate buckets that start at 00, 05, 10, ...
  let next = start - (start % stepSize);

  while (end >= next) {
    result.push({ start: next, end: next + stepSize - 1 });
    next += stepSize;
  }

  // cut first and last bucket short e.g. 03 - 05, 05 - 10, 10 - 12
  result[0].start = start;
  result[result.length - 1].end = end;

  return result;
};

export const getSessionAggregates = (session: Observation[]): any => {
  if (session.length < 2) return; // with less than 2 you cannot compute a time range

  // 1. divide them in buckets of 5'
  const sorted = session.sort((a, b) => a.timestamp - b.timestamp);
  const first = sorted[0];
  const last = sorted[sorted.length - 1];

  const interval = last.timestamp - first.timestamp;
  console.log("short interval?", interval < FIVE_MINUTES_IN_MS);
  if (interval < FIVE_MINUTES_IN_MS) return;

  const timeSlots = calculateTimeSteps(
    first.timestamp,
    last.timestamp,
    FIVE_MINUTES_IN_MS
  );
  const map: Map<TimeSlot, Observation[]> = new Map();
  timeSlots.forEach(timeSlot => map.set(timeSlot, []));

  map.forEach((obsArr, timeslot) => {
    // should also be reversed for optimal speed
    for (let i = sorted.length - 1; i >= 0; i--) {
      const obs = sorted[i];
      if (timeslot.start < obs.timestamp && obs.timestamp < timeslot.end) {
        const removed = sorted.splice(i, 1)[0];
        obsArr.push(removed);
      }
    }
  });

  // 2. get modality counts per timeslot
  const aggregates: TimeSlotAggregate[] = [];
  map.forEach((obsArr, timeslot) => {
    const groupedByModality = groupBy(obsArr, "modality");
    // get the modality counts
    const modalityCounts = Object.keys(groupedByModality).reduce(
      (acc: any, key: string) => {
        acc[key] = getTotal(groupedByModality[key]);
        return acc;
      },
      {}
    );

    aggregates.push({
      [Modality.car]: 0,
      [Modality.pedestrian]: 0,
      [Modality.bike]: 0,
      [Modality.velo]: 0,
      [Modality.truck]: 0,
      [Modality.publicTransport]: 0,
      sessionId: session[0].sessionId,
      objectId: session[0].segmentId,
      manualStreetName: session[0].manualStreetName,
      ...modalityCounts,
      ...timeslot,
    });
  });

  return aggregates;
};

export const attachMetadata = (
  data: TimeSlotAggregate[],
  metadata: Metadata[]
) => {
  return data.map(timeslot => {
    const matchingMeta = metadata.find(
      meta => meta.sessionId === timeslot.sessionId
    );
    console.log(matchingMeta);
    return {
      ...timeslot,
      ...matchingMeta,
    };
  });
};

export const formatForCsv = (
  data: { [key: string]: Session } | undefined
): TimeSlotAggregate[] => {
  if (!data) return [];

  const result = Object.values(data).map(observations => {
    return getSessionAggregates(Object.values(observations));
  });
  return flatten(result.filter(res => res)); // filter out the 'undefined' -> observations with no timeslots longer than 5'
};

export const msToSeconds = (ms: number): number => {
  return Math.floor(ms / 1000);
};
