import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { useStore } from "../StoreContext";

const Instructions: React.FC = observer(() => {
  const { city } = useStore();
  const history = useHistory();

  return (
    <>
      <div className="CFBackGroundImg" />
      <div className="CFWelcometx">
        <h1>Instructions</h1>
        <div className="CFListInstructions">
          <div className="alright">
            <h1>1</h1>
            <p>
              Find a comfortable place where you can see all the street
              including sidewalks, preferably with a backrest
            </p>
          </div>
          <div className="alright">
            <h1>2</h1>
            <p>
              Visualize and imaginary line in front of you that cross the full
              road section
            </p>
          </div>
          <div className="alright">
            <h1>3</h1>
            <p>
              Count everything that cross the line, count the faster objects
              first
            </p>
          </div>
          <div className="alright">
            <h1>4</h1>
            <p>
              Focus, try to be as correct as possible when you count. Small
              offsets can have a big impact on the data
            </p>
          </div>
          <div className="alright">
            <h1>5</h1>
            <p>Follow the currrent COVID-19 measures</p>
          </div>
        </div>

        <p>Thank you for your help!</p>

        <div>
          <button onClick={() => history.push(`/${city}/map`)}>
            Select Road
          </button>
        </div>
      </div>
    </>
  );
});

export default Instructions;
