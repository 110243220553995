import * as React from "react";
import { Link } from "react-router-dom";

import { City } from "../../types";
import styles from "./index.module.css";

const ChooseCityPage: React.FC = () => {
  const classNames = `button ${styles.link}`;

  return (
    <div className={styles.container}>
      <div className="CFBackGroundImg" />
      <main className="CFChooseCity">
        <h1>City Flows Validation App</h1>
        <p>Please select the city you want to count in</p>
        <div className={styles.buttonGroup}>
          {Object.keys(City).map((city) => (
            <Link key={city} to={city} className={classNames}>
              {city}
            </Link>
          ))}
        </div>
      </main>
    </div>
  );
};

export default ChooseCityPage;
