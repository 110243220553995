import * as React from "react";
import { observer } from "mobx-react";

import { useStore } from "../../StoreContext";
import { Modality } from "../../types";
import { getTotal } from "../../helper";
import { useUpdateCounterCallback } from "../../hooks/counter";
import { modalityMetaDataMap } from "../../Utilities/Modalities";
import plusIcon from "../../_img/otherimages/plus.svg";
import minusIcon from "../../_img/otherimages/minus.svg";

interface IModalityCounterProps {
  modality: Modality;
}

const ModalityCounter: React.FC<IModalityCounterProps> = observer(
  ({ modality }) => {
    const store = useStore();
    const updateCounter = useUpdateCounterCallback();

    return (
      <div className="modContainer">
        <button
          onClick={() => {
            if (getTotal(store.sortedCounts[modality]) > 0) {
              updateCounter(modality, -1);
            }
          }}
        >
          <img src={minusIcon} alt="minus" />
        </button>
        <div className="modCenter">
          <div className="modLogo">
            <img src={modalityMetaDataMap[modality].logo} alt={modality} />
            <div>{modalityMetaDataMap[modality].caption}</div>
          </div>
          <span className="ModScoretxt">
            {getTotal(store.sortedCounts[modality])}
          </span>
        </div>
        <button onClick={() => updateCounter(modality, 1)}>
          <img src={plusIcon} alt="plus" />
        </button>
      </div>
    );
  }
);

export default ModalityCounter;
