import { IconLayer } from "@deck.gl/layers";

import { useGeoLocation } from "../../GeoLocationProvider";

const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 192, height: 192, mask: true },
};

export const useIconLayer = () => {
  const [, { position }] = useGeoLocation();

  const data = [
    { coordinates: [position?.coords.longitude, position?.coords.latitude] },
  ];

  const layer = new IconLayer({
    id: "icon-layer",
    data,
    getColor: () => [69, 187, 198],
    getIcon: () => "marker",
    getPosition: (d: any) => d.coordinates,
    getSize: () => 5,
    iconAtlas: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23000000' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpath d='M127.99414,15.9971a88.1046,88.1046,0,0,0-88,88c0,75.29688,80,132.17188,83.40625,134.55469a8.023,8.023,0,0,0,9.1875,0c3.40625-2.38281,83.40625-59.25781,83.40625-134.55469A88.10459,88.10459,0,0,0,127.99414,15.9971ZM128,72a32,32,0,1,1-32,32A31.99909,31.99909,0,0,1,128,72Z'%3E%3C/path%3E%3C/svg%3E",
    iconMapping: ICON_MAPPING,
    sizeScale: 7,
  });

  return layer;
};
