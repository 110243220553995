import React, { useState } from "react";
import { observer } from "mobx-react";
import { useHistory, Redirect } from "react-router-dom";

import { useStore } from "../../StoreContext";
import { Modality } from "../../types";
import Timer from "./Timer";
import ModalityCounter from "./ModalityCounter";
import { useUpdateCounterCallback } from "../../hooks/counter";
import { Modal } from "../../components/Modal";

import {
  SuccessModalContents,
  FailureModalContents,
  InfoModalContents,
} from "../../components/ModalContents";
import { useFirebaseClient } from "../../hooks/firebase";

type ModalType = "none" | "success" | "failure" | "info";

const Counter: React.FC = observer(() => {
  const store = useStore();
  const history = useHistory();
  const fb = useFirebaseClient();
  const { location, modalities } = store;
  const updateCounter = useUpdateCounterCallback();

  if (!location) {
    return <Redirect to={`/${store.city}/map`} />;
  }

  const [showModal, setShowModal] = useState(false);
  const [currentModalType, setCurrentModalType] = useState<ModalType>("none");

  const getContentForType = () => {
    switch (currentModalType) {
      case "success":
        return <SuccessModalContents handleOk={endCounting} />;
      case "failure":
        return (
          <FailureModalContents
            timeLeftString={store.remainingTimeString}
            handleCancel={hideModal}
            handleOk={endCounting}
          />
        );
      case "info":
        return <InfoModalContents />;
      default:
        return undefined;
    }
  };

  const goToEnd = () => {
    hideModal();
    history.push(`/${store.city}/thank-you`);
  };

  const hideModal = () => {
    setCurrentModalType("none");
    setShowModal(false);
  };

  const endCounting = () => {
    goToEnd();
    updateCounter(Modality.car, 0); // send 'end' message
    if (!!store.trafficPressure) {
      fb.writeMetadata(
        { trafficPressure: store.trafficPressure, feedback: store.feedback },
        store.sessionId
      );
    }
  };

  const onHandleDone = () => {
    if (store.timeBuckets.length <= 1) setCurrentModalType("failure");
    else setCurrentModalType("success");
    setShowModal(true);
  };

  const showInfo = () => {
    setCurrentModalType("info");
    setShowModal(true);
  };

  return (
    <>
      <div className="countContainer">
        <Timer />
        <br />
        {modalities.map((modality) => (
          <ModalityCounter key={modality} modality={modality} />
        ))}
        <div className="countFooter">
          <button className="question-mark-button" onClick={showInfo}>
            ?
          </button>
          <span style={{ width: "100px" }} />
          <button onClick={onHandleDone}>I'm done!</button>
        </div>
      </div>
      <Modal
        className={currentModalType.toString()}
        visible={showModal}
        showClose={currentModalType !== "success"}
        handleClose={(event) => {
          setShowModal(false);
        }}
      >
        {getContentForType()}
      </Modal>
    </>
  );
});

export default Counter;
