import "./feedback.css";

import * as React from "react";
import { observer } from "mobx-react";

import { useStore } from "../StoreContext";
import { useFirebaseClient } from "../hooks/firebase";

const Feedback: React.FunctionComponent = observer(() => {
  const store = useStore();
  const fb = useFirebaseClient();
  const [feedbackState, setFeedbackState] = React.useState<
    "notStarted" | "started" | "sending" | "sent"
  >("notStarted");

  const handleSend = () => {
    if (store.feedback && store.sessionId) {
      fb.writeMetadata(
        { trafficPressure: store.trafficPressure, feedback: store.feedback },
        store.sessionId
      );
      setFeedbackState("sent");
    } else {
      setFeedbackState("notStarted");
    }
  };

  switch (feedbackState) {
    case "started":
      return (
        <form className="feedback-form">
          <textarea
            autoFocus
            value={store.feedback}
            onChange={e => (store.feedback = e.target.value)}
          />
          <button className="feedback-send-button" onClick={handleSend}>
            Send
          </button>
        </form>
      );

    case "sent":
      return (
        <>
          <div>Thank you for your feedback!</div>
          <button onClick={() => setFeedbackState("started")}>change</button>
        </>
      );
    default:
      return (
        <div
          className="feedback-button"
          onClick={() => setFeedbackState("started")}
        >
          Leave feedback
        </div>
      );
  }
});

export default Feedback;
