import * as React from "react";
import CsvDownloader from "react-csv-downloader";

import { useFirebaseClient } from "../../hooks/firebase";
import { useStore } from "../../StoreContext";
import { formatForCsv, attachMetadata } from "../../helper";
import { Modality } from "../../types";

const MODALITY_DISPLAY_NAME_MAPPING = new Map([
  [Modality.car, "car"],
  [Modality.pedestrian, "pedestrian"],
  [Modality.pedestrian, "pedestrian"],
  [Modality.bike, "bike"],
  [Modality.velo, "velo"],
  [Modality.truck, "truck"],
  [Modality.publicTransport, "publicTransport"],
]);

const COMMON_CSV_COLUMNS = [
  {
    id: "start",
    displayName: "start",
  },
  {
    id: "end",
    displayName: "end",
  },
  {
    id: "sessionId",
    displayName: "sessionId",
  },
  {
    id: "objectId",
    displayName: "objectId",
  },
  {
    id: "trafficPressure",
    displayName: "trafficPressure",
  },
  {
    id: "feedback",
    displayName: "feedback",
  },
  {
    id: "manualStreetName",
    displayName: "manualStreetName",
  },
];

const Export: React.FunctionComponent = () => {
  const fb = useFirebaseClient();
  const store = useStore();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>();

  const handleFetchDataClick = async () => {
    setIsFetching(true);
    const result = await fb.fetchAllSessions();
    const dataAsCsv = formatForCsv(result);
    const metadata = await fb.fetchAllmetadata();
    const csvWithMetadata = attachMetadata(dataAsCsv, metadata);
    setData(csvWithMetadata);
    setIsFetching(false);
  };

  const csvColumns = React.useMemo(() => {
    const modalityColumns = store.modalities.map((modality) => ({
      id: modality,
      displayName: MODALITY_DISPLAY_NAME_MAPPING.get(modality),
    }));

    return [...modalityColumns, ...COMMON_CSV_COLUMNS];
  }, [store.modalities]);

  return (
    <div>
      <h1>Export results</h1>
      {isFetching ? (
        "fetching data. This could take a while ..."
      ) : (
        <button onClick={handleFetchDataClick}>
          {data ? "refetch data" : "fetch data"}
        </button>
      )}
      {data && (
        <CsvDownloader
          filename={`cf_data_validator_${store.city}_${process.env.NODE_ENV}_export`}
          prefix=""
          suffix=""
          separator=";"
          wrapColumnChar=""
          columns={csvColumns}
          datas={data}
          text="DOWNLOAD CSV"
        />
      )}
      <div>
        {data && (
          <span>
            {data.length} buckets of 5' are fetched from the server. You can
            download them using the button above
          </span>
        )}
      </div>
    </div>
  );
};

export default Export;
