import * as React from "react";
import { GeoJsonLayer } from "@deck.gl/layers";

import * as colors from "./colors";
import { useStore } from "../../StoreContext";

const baseWidth = 1;
const extendedWidth = 2;

/**
 * Dynamically import geo json based on the city
 *
 * @returns The Geo json for the city
 */
const useGeoJsonData = () => {
  const { streetSegmentLoader } = useStore();
  const [geoJsonData, setGeoJsonData] = React.useState<unknown>();

  React.useEffect(() => {
    const loadResource = async () => {
      const module = await streetSegmentLoader();
      setGeoJsonData(module.default);
    };

    loadResource();
  }, [streetSegmentLoader]);

  return geoJsonData;
};

export const useStreetLayer = (isVisble: boolean) => {
  const store = useStore();
  const geoJsonData = useGeoJsonData();
  const currentStreetID = store.selectedSegment?.WS_OIDN ?? -1000;

  return new GeoJsonLayer({
    id: "street-layer",
    data: geoJsonData,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: false,
    visible: isVisble,
    lineWidthScale: 10,
    lineWidthMinPixels: 1,
    getFillColor: [0, 0, 0, 100],
    getLineColor: (e: any) => {
      return e.properties.WS_OIDN === currentStreetID
        ? colors.ACCENT_COLOR
        : colors.PRIMARY_COLOR;
    },
    getLineWidth: (e: any) => {
      return e.properties.WS_OIDN === currentStreetID
        ? extendedWidth
        : baseWidth;
    },
    updateTriggers: {
      getLineColor: currentStreetID,
      getLineWidth: currentStreetID,
    },
    onClick: (e: any) => {
      store.selectedSegment = e.object.properties;
    },
    // onHover: ({ object, x, y }: any) => {
    //   console.log(object.id, object.properties.length);
    //   /* Update tooltip
    //      http://deck.gl/#/documentation/developer-guide/adding-interactivity?section=example-display-a-tooltip-for-hovered-object
    //   */
    // }
  });
};
