import { useStore } from "../StoreContext";
import { Modality, Observation } from "../types";
import { useFirebaseClient } from "../hooks/firebase";

export const useUpdateCounterCallback = () => {
  const store = useStore();
  const fb = useFirebaseClient();

  return (modality: Modality, value: number) => {
    const observation: Observation = {
      sessionId: store.sessionId,
      timestamp: new Date().getTime(),
      modality,
      count: value,
      segmentId: store.location?.id ?? "",
      manualStreetName: store.manualStreetName,
    };
    // store in firebase
    fb.writeObservation(observation);
    // store in mobx store
    store.countLog.push(observation);
  };
};
