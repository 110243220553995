import * as React from "react";
import { observer } from "mobx-react";
import { useRouteMatch, useHistory } from "react-router-dom";

import { useStore } from "../StoreContext";

const WelcomeScreen: React.FC = observer(() => {
  const { city } = useStore();
  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <main>
      <div className="CFBackGroundImg" />
      <div className="CFWelcometx">
        <h1>Hi!</h1>
        <p>
          Welcome to the CityFlows validation app for{" "}
          <strong className="city-name">{city}</strong>!
        </p>
        <p>
          Count the number of modalites you see on the street for (at least) 5
          minutes
        </p>

        <p>
          CityFlows is investigating how to automatically compute flows in the
          city.
        </p>

        <p>
          Your valuable contribution will help use to check whether we are on
          the right track.
        </p>

        <p>Thank you for your help!</p>

        <div>
          <button onClick={() => history.push(`${url}/map`)}>
            Let’s start!
          </button>
          <button onClick={() => history.push(`${url}/instructions`)}>
            Instructions
          </button>
        </div>
        <p>
          <a
            href="https://www.imeccityofthings.be/en/projecten/cityflows"
            target="blank"
            rel="noopener noreferrer"
          >
            Learn more about CityFlows
          </a>
        </p>
      </div>
    </main>
  );
});

export default WelcomeScreen;
