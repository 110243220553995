import "./App.css";

import * as React from "react";
import { observer } from "mobx-react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import WelcomeScreen from "./pages/WelcomeScreen";
import Instructions from "./pages/Instructions";
import ChooseCityPage from "./pages/ChooseCity";
import Map from "./pages/streetPicker/Map";
import Counter from "./pages/Counter";
import Export from "./pages/Export/Index";
import LastScreen from "./pages/LastScreen";
import { Store } from "./store";
import { City } from "./types";
import { StoreProvider } from "./StoreContext";
import { GeoLocationProvider } from "./GeoLocationProvider";

const AppPages: React.FC = observer(() => {
  const { city } = useParams<{ city: string }>();
  const { path } = useRouteMatch();

  // Shallow comparison, otherwise store would be created on every render
  const store = React.useMemo(() => new Store(city as City), [city]);

  return (
    <GeoLocationProvider>
      <StoreProvider store={store}>
        <Header />
        <Switch>
          <Route path={path}>
            <Switch>
              <Route path={path} component={WelcomeScreen} exact />
              <Route path={`${path}/instructions`} component={Instructions} />
              <Route path={`${path}/map`} component={Map} />
              <Route path={`${path}/counter`} component={Counter} />
              <Route path={`${path}/thank-you`} component={LastScreen} />
              <Route path={`${path}/export`} component={Export} />
            </Switch>
          </Route>
        </Switch>
        <Footer />
      </StoreProvider>
    </GeoLocationProvider>
  );
});

const App = () => {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" component={ChooseCityPage} exact />
          <Route path="/:city" component={AppPages} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
