import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { useStore } from "../StoreContext";
import GraphReport from "../components/GraphReport";
import Feedback from "../components/Feedback";

const LastScreen: React.FC = observer(() => {
  const store = useStore();
  const history = useHistory();

  return (
    <>
      <div className="CFBackGroundImg" />
      <div className="CFWelcometx">
        <h1>Thank you!</h1>
        <p>You have counted {Math.ceil(store.timer / 60)} minutes.</p>
        <p>
          Your valuable contribution will help use to check whether we are on
          the right track.
        </p>
        <GraphReport />

        <p>Thank you for your help!</p>
        <button
          onClick={() => {
            store.reset();
            history.push("/");
          }}
        >
          Back to home
        </button>
        <Feedback />
        <p>
          <a
            href="https://www.imeccityofthings.be/en/projecten/cityflows"
            target="blank"
            rel="noopener noreferrer"
          >
            Learn more about CityFlows
          </a>
        </p>
      </div>
    </>
  );
});

export default LastScreen;
