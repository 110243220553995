import * as React from "react";
import { observer } from "mobx-react";

import { useStore } from "../StoreContext";
import TabSelection from "./TabSelection";
import { Modality } from "../types";
import { modalityMetaDataMap } from "../Utilities/Modalities";
import sadFace from "../_img/moods/sad_face.svg";
import happyFace from "../_img/moods/happy_face.svg";

interface ContentProps {
  timeLeftString?: string;
  handleOk?: () => void;
  handleCancel?: () => void;
}

export const FailureModalContents: React.FC<ContentProps> = ({
  timeLeftString,
  handleCancel,
  handleOk,
}) => {
  if (!timeLeftString) {
    return null;
  }

  return (
    <>
      <img src={sadFace} alt="sadFace" />
      <h2>Are you sure?</h2>
      <p>Do you really want to end this session now?</p>
      <p>You still have:</p>
      <p>{timeLeftString}</p>
      <button onClick={handleOk}>YES, I'M DONE</button>
      <button onClick={handleCancel}>NO, CONTINUE</button>
    </>
  );
};

export const SuccessModalContents: React.FC<ContentProps> = observer(
  ({ handleOk }) => {
    const store = useStore();

    return (
      <>
        <img src={happyFace} alt="happy face" />
        <h2>Great!</h2>
        <p>You have completed your counting session</p>
        <p>Can we ask you one more thing?</p>
        <p>How busy was your street while you were counting?</p>
        <TabSelection
          options={[
            { value: "light", label: "Quiet" },
            { value: "normal", label: "Average" },
            { value: "heavy", label: "Very busy" },
          ]}
          selectedValue={store.trafficPressure}
          handleSelectValue={(value) => {
            store.trafficPressure = value;
          }}
        />

        <button onClick={handleOk} disabled={!store.trafficPressure}>
          Submit Data
        </button>
      </>
    );
  }
);

export const InfoModalContents: React.FC<ContentProps> = () => {
  const { modalities } = useStore();

  return (
    <table>
      <tbody>
        {modalities.map((modality) => (
          <ModalityItem key={modality} modality={modality} />
        ))}
      </tbody>
    </table>
  );
};

type ModalityItemProps = {
  modality: Modality;
};

const ModalityItem: React.FC<ModalityItemProps> = ({ modality }) => {
  const metadata = modalityMetaDataMap[modality];

  return (
    <tr key={modality}>
      <td>
        <img src={metadata.logo} alt={metadata.caption} />
      </td>
      <td>{metadata.info}</td>
    </tr>
  );
};
