import * as React from "react";

import { useStore } from "../../StoreContext";

const Badges: React.FunctionComponent<any> = () => {
  const store = useStore();

  return (
    <div className="badge-wrapper">
      {store.timeBuckets.map((bucket, i) => {
        return (
          <div
            className="badge"
            key={bucket.start}
            style={
              !bucket.isCompleted
                ? {
                    background: "rgba(255,255,255,.2)",
                  }
                : {}
            }
          >
            {bucket.isCompleted && `${(i + 1) * 5}`}
          </div>
        );
      })}
    </div>
  );
};

export default Badges;
