import "../App.css";

import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useStore } from "../StoreContext";
import CFLogoPanel from "../_img/logos/CFLogoPanel";
import CFHomePanel from "../_img/logos/CFHomeicondiv";

const Header: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  const isMatch = useRouteMatch("/:city/counter");
  const homeButtonVisible = !Boolean(isMatch);

  const goHome = () => {
    if (homeButtonVisible) {
      store.reset();
      history.push("/");
    }
  };

  return (
    <header className="headerstyle">
      <CFLogoPanel />

      <h1 className="headertitle">Validation App</h1>
      <span
        style={{ visibility: homeButtonVisible ? "unset" : "hidden" }}
        onClick={goHome}
      >
        <CFHomePanel />
      </span>
    </header>
  );
};

export default Header;
