import * as React from "react";
import "./tabSelection.css";

interface TabSelectionProps {
  options: {
    value: string;
    label: string;
  }[];
  selectedValue: string | undefined;
  handleSelectValue: (value: string) => any;
}

const TabSelection: React.FunctionComponent<TabSelectionProps> = ({
  options,
  selectedValue,
  handleSelectValue,
}) => {
  return (
    <div className="tabselection-container">
      {options.map(({ value, label }) => (
        <button
          key={value}
          onClick={() => handleSelectValue(value)}
          style={
            value === selectedValue
              ? { background: "white", color: "black" }
              : {}
          }
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default TabSelection;
