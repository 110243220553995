import { observable, computed } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { SegmentProperties, Observation, Modality } from "../types";
import { msToSeconds } from "../helper";
import { City } from "../types";
import {
  ANTWERP_CONFIG,
  MECHELEN_CONFIG,
  GHENT_CONFIG,
  DEFAULT_VIEWPORT,
  Loader,
} from "./configs";

interface Location {
  streetName: string;
  id: number | null;
}

interface SortedCount {
  [Modality.car]: Observation[];
  [Modality.pedestrian]: Observation[];
  [Modality.bike]: Observation[];
  [Modality.velo]: Observation[];
  [Modality.truck]: Observation[];
  [Modality.publicTransport]: Observation[];
  // [K in Modality]?: Observation[]
}

interface TimeBucket {
  start: number; // ms
  end: number; // ms
  duration: number; // seconds
  elapsed: number; // seconds
  isCompleted: boolean;
}
const FIVE_MINUTES_IN_S = 5 * 60;

export class Store {
  @observable city: City;
  @observable selectedSegment: SegmentProperties | null = null;
  @observable viewport = DEFAULT_VIEWPORT;
  @observable timer: number = 0;
  iterationTime: number = FIVE_MINUTES_IN_S;
  @observable observationStart: Date | null = null;
  @observable countLog: Observation[] = [];
  @observable trafficPressure: string = "";
  sessionId = uuidv4();
  accumulator: SortedCount;
  @observable feedback: string = "";
  @observable manualStreetName = "";

  @observable
  modalities: Modality[] = [];

  @observable
  streetSegmentLoader: Loader;

  constructor(city: City) {
    const accumulator: any = {};
    Object.values(Modality).forEach((modality) => {
      accumulator[modality] = [];
    });
    this.accumulator = accumulator;
    this.city = city;

    const { modalities, viewport, streetSegmentLoader } = this.getConfigForCity(
      this.city
    );

    this.modalities = modalities;
    this.viewport = viewport;
    this.streetSegmentLoader = streetSegmentLoader;
  }

  public reset() {
    this.sessionId = uuidv4();
    this.selectedSegment = null;
    this.viewport = DEFAULT_VIEWPORT;
    this.timer = 0;
    this.observationStart = null;
    this.countLog = [];
    this.trafficPressure = "";
  }

  @computed get location(): Location | null {
    const streetName =
      this.selectedSegment?.NAAM ||
      this.selectedSegment?.RSTRNM ||
      "STREETNAME UNKNOWN";

    // We don't have a segment id
    if (this.manualStreetName) {
      return {
        id: null,
        streetName: this.manualStreetName,
      };
    }

    return this.selectedSegment
      ? {
          streetName,
          id: this.selectedSegment?.WS_OIDN,
        }
      : null;
  }

  @computed get timeBuckets(): TimeBucket[] {
    if (!this.observationStart) {
      throw new Error("No observation start. Did you already start counting?");
    }
    const nowS = msToSeconds(this.observationStart.getTime()) + this.timer;
    const buckets = [];
    const startS = msToSeconds(this.observationStart.getTime());
    let next = startS - (startS % this.iterationTime);

    while (nowS >= next) {
      // the first bucket is bigger e.g. 03 - 10 instead of 03 - 05 (otherwise it's not a complete 5')
      if (buckets.length === 0) {
        const endS = next + 2 * this.iterationTime - 1;
        const duration = endS - startS;
        const elapsed = nowS - startS;
        buckets.push({
          start: startS * 1000,
          end: endS * 1000,
          duration,
          elapsed: elapsed < duration ? elapsed : duration,
          isCompleted: endS - nowS <= 0,
        });
        next += 2 * this.iterationTime;
      } else {
        // all buckets after the first one are normal length e.g. 05 - 10, 10 - 15
        const endS = next + this.iterationTime - 1;
        const duration = endS - next;
        const elapsed = nowS - next;
        buckets.push({
          start: next * 1000,
          end: endS * 1000,
          duration,
          elapsed: elapsed < duration ? elapsed : duration,
          isCompleted: endS - nowS <= 0,
        });
        next += this.iterationTime;
      }
    }

    return buckets;
  }

  @computed get currentBucket(): TimeBucket {
    return this.timeBuckets[this.timeBuckets.length - 1];
  }

  @computed get sortedCounts(): SortedCount {
    const acc = { ...this.accumulator };
    this.countLog.forEach((item: Observation) => {
      acc[item.modality] = [...acc[item.modality], item];
    });
    return acc;
    // return this.countLog.reduce((acc, Observation) => {
    //   return acc[Observation.modality].push(Observation)
    // }, this.accumulator)
  }

  @computed get remainingTimeString(): string {
    const remaining = this.currentBucket.duration - this.currentBucket.elapsed;
    return remaining < 60
      ? `${remaining}s`
      : `${Math.floor(remaining / 60)}m ${String(remaining % 60).padStart(
          2,
          "0"
        )}s`;
  }

  private getConfigForCity(city: City) {
    switch (city) {
      case City.antwerp:
        return ANTWERP_CONFIG;
      case City.mechelen:
        return MECHELEN_CONFIG;
      case City.ghent:
        return GHENT_CONFIG;
      default:
        throw new Error(
          `Either the given city [${city}] is incorrect or no valid config is available for the city [${city}]`
        );
    }
  }
}
