import "firebase/database";
import firebase from "firebase/app";

import { Observation, Metadata } from "../types";
import { City } from "../types";
// Your web app's Firebase configuration

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export class Firebase {
  app: firebase.app.App;
  db: firebase.database.Database;
  // Used to determine the write path in the db
  city: City;

  constructor(city: City) {
    // Only initialize once
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(config);
    } else {
      this.app = firebase.app();
    }
    this.db = this.app.database();
    this.city = city;
  }

  writeObservation(observation: Observation): Promise<void> {
    return new Promise((resolve, reject) => {
      const { sessionId } = observation;
      this.db
        .ref(`${process.env.NODE_ENV}/${this.city}/sessions/${sessionId}`)
        .push(observation, function (error) {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        });
    });
  }

  async fetchAllSessions(): Promise<any> {
    try {
      const result = await this.db
        .ref(`${process.env.NODE_ENV}/${this.city}/sessions`)
        .once("value");
      return result.val();
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async fetchAllmetadata(): Promise<Metadata[]> {
    try {
      const result = await this.db
        .ref(`${process.env.NODE_ENV}/${this.city}/metadata`)
        .once("value");

      return Object.values(result.val());
    } catch (e) {
      console.log('no metadata found!')
      console.warn(`Something went wrong while fetching the metadata: ${e}`);
      return [];
    }
  }

  async writeMetadata(
    metadata: { trafficPressure: string; feedback: string },
    sessionId: string
  ): Promise<void> {
    const data = { ...metadata, sessionId };
    return new Promise((resolve, reject) => {
      this.db
        .ref(`${process.env.NODE_ENV}/${this.city}/metadata/${sessionId}`)
        .set(data, function (error) {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        });
    });
  }
}
