import * as React from "react";

import { useStore } from "../StoreContext";
import { Firebase } from "../Firebase/Firebase";

export const useFirebaseClient = () => {
  const store = useStore();

  const firebaseClient = React.useMemo(
    () => new Firebase(store.city),
    [store.city]
  );

  return firebaseClient;
};
