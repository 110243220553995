import * as React from "react";

type UseGeoLocation = [
  () => void,
  { position?: GeolocationPosition; isSupported: boolean; error: string }
];

const GeoLocationContext = React.createContext<UseGeoLocation | null>(null);

export const useGeoLocation = () => {
  const context = React.useContext(GeoLocationContext);

  if (!context) {
    throw new Error(
      "useGeoLocation must be used within a `GeoLocationProvider`"
    );
  }

  return context;
};

const ERRORS = {
  NOT_SUPPORTED: "Geolocation is not supported by your browser",
  COULD_NOT_RETRIEVE: "Could not retrieve geolocation",
};

export const GeoLocationProvider: React.FC = ({ children }) => {
  const [position, setPosition] = React.useState<GeolocationPosition>();
  const [isSupported, setIsSupported] = React.useState(true);
  const [error, setError] = React.useState<string>();
  const watchId = React.useRef<number>();

  React.useEffect(() => {
    return () => {
      if (watchId.current) {
        navigator.geolocation.clearWatch(watchId.current);
      }
    };
  }, []);

  const onSuccess: PositionCallback = React.useCallback((position) => {
    setPosition(position);
  }, []);

  const onError: PositionErrorCallback = React.useCallback((error) => {
    setError(ERRORS.COULD_NOT_RETRIEVE);
  }, []);

  const getPosition = React.useCallback(() => {
    if (!("geolocation" in navigator)) {
      setError(ERRORS.NOT_SUPPORTED);
      setIsSupported(false);
    } else {
      watchId.current = navigator.geolocation.watchPosition(onSuccess, onError);
    }
  }, [onError, onSuccess]);

  const value = React.useMemo(
    () => [getPosition, { position, isSupported, error }] as UseGeoLocation,
    [error, getPosition, position, isSupported]
  );

  return (
    <GeoLocationContext.Provider value={value}>
      {children}
    </GeoLocationContext.Provider>
  );
};
