import { Modality } from "../types";

type Viewport = {
  latitude: number;
  longitude: number;
  zoom: number;
  minZoom: number;
  maxZoom: number;
  pitch: number;
  bearing: number;
};

export type Loader = () => Promise<{ default: any } | any>;

type CityConfig = {
  modalities: Modality[];
  viewport: Viewport;
  streetSegmentLoader: Loader;
};

export const DEFAULT_VIEWPORT: Viewport = {
  latitude: 51.2147,
  longitude: 4.39655,
  zoom: 14.5,
  minZoom: 8,
  maxZoom: 21,
  pitch: 0,
  bearing: 0,
};

export const ANTWERP_CONFIG: CityConfig = {
  viewport: {
    ...DEFAULT_VIEWPORT,
    latitude: 51.2147,
    longitude: 4.39655,
  },
  modalities: [
    Modality.car,
    Modality.pedestrian,
    Modality.bike,
    Modality.velo,
    Modality.truck,
    Modality.publicTransport,
  ],
  streetSegmentLoader: () => import("../data/antwerp/wegregister.json"),
};

export const MECHELEN_CONFIG: CityConfig = {
  viewport: {
    ...DEFAULT_VIEWPORT,
    latitude: 51.0182147,
    longitude: 4.4672147,
  },
  modalities: [
    Modality.car,
    Modality.pedestrian,
    Modality.bike,
    Modality.truck,
    Modality.publicTransport,
  ],
  streetSegmentLoader: () => import("../data/mechelen/wegregister.json"),
};

export const GHENT_CONFIG: CityConfig = {
  viewport: {
    ...DEFAULT_VIEWPORT,
    latitude: 51.00882,
    longitude: 3.70375,
  },
  modalities: [
    Modality.car,
    Modality.pedestrian,
    Modality.bike,
    Modality.truck,
    Modality.publicTransport,
  ],
  streetSegmentLoader: () => import("../data/ghent/wegregister.json"),
};
