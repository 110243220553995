import React from "react";

interface Props {
  visible: boolean;
  className?: string;
  showClose: boolean;
  handleClose?: (e: any) => void;
}

export const Modal: React.FC<Props> = (props) => {
  const display = props.visible ? "block" : "none";
  return (
    <div className={`modal ${props.className}`} style={{ display: display }}>
      <div className={`modal-content ${props.className}`}>
        <div className={`modal-header ${props.className}`}>
          {props.showClose && (
            <span className="close" onClick={props.handleClose}>
              &times;
            </span>
          )}
        </div>
        <div className={`modal-body ${props.className}`}>{props.children}</div>
      </div>
    </div>
  );
};
