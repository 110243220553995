import React from "react";
import "../../App.css";
import lcityflows from "./cityflows_logo.svg";

const CFLogoPanel: React.FunctionComponent = () => {
  return (
    <a
      href="https://www.imeccityofthings.be/en/projecten/cityflows"
      target="blank"
      rel="noopener noreferrer"
      className="lcityflows"
    >
      <img src={lcityflows} alt="CityFlows" />
    </a>
  );
};

export default CFLogoPanel;
