import * as React from "react";
import { getTotal } from "../helper";
import { modalityMetaDataMap } from "../Utilities/Modalities";
import { useStore } from "../StoreContext";

import "./graphReport.css";

const GraphReport: React.FunctionComponent = () => {
  const { modalities, sortedCounts } = useStore();

  const dataWithCounts = modalities.map((modality) => ({
    ...modalityMetaDataMap[modality],
    count: getTotal(sortedCounts[modality]),
    name: modality,
  }));
  const max = Math.max(...dataWithCounts.map(d => d.count));
  const dataWithPercentages = dataWithCounts.map(d => ({
    ...d,
    percentage: d.count > 0 ? (d.count / max) * 100 : 0,
  }));
  return (
    <div className="graph-wrapper">
      {dataWithPercentages.map(mod => {
        return (
          <div key={mod.name} className="bar-container">
            <div className="outer-bar">
              <div
                className="inner-bar"
                style={{ height: `${mod.percentage}%` }}
              ></div>
              <span className="count-text">
                {mod.count >= 0 ? mod.count : 0}
              </span>
            </div>
            <div className="image-wrapper">
              <img src={mod.logo} alt={mod.caption} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GraphReport;
